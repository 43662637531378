<template>
    <div>
        <v-container class="paper-border">
            <v-row no-gutters>
                <v-col cols="12" class="font-weight-bold">{{ $t('message.mainDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.location') }}:</v-col>
                <v-col cols="6">
                    <span :class="Wpr__office_id != null ? null : 'red--text'">{{ Wpr__office_id != null ? offices.find(office => office.Office.id == Wpr__office_id).Office.title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.supplier') }}:</v-col>
                <v-col cols="6">
                    <span :class="Wpr__supplier_id != null ? null : 'red--text'">{{ Wpr__supplier_id != null ? allSuppliers.find(supplier => supplier.Supplier.id == Wpr__supplier_id).Supplier.title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.agreedPaymentTerms') }}:</v-col>
                <v-col cols="6">
                    <span :class="Wpr__paymentstatement_id != null ? null : 'red--text'">{{ Wpr__paymentstatement_id != null ? allSupplierPaymentTerms.find(term => term.Paymentterm.id == Wpr__paymentstatement_id).Paymentterm.title : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.specifications') }}</v-col>
            </v-row>
            <v-row no-gutters v-for="item in Wpritems" v-bind:key="item.index">
                <v-col cols="12">
                    <v-row no-gutters :class="item.index > 0 ? 'pt-3' : ''">
                        <v-col cols="6">{{ "#" + item.lineNumber  }}<span class="pl-2">{{ $t('message.certification') }}:</span></v-col>
                        <v-col cols="6">{{ getCertification(item.Wpritem.certification_id) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.species') }}:</v-col>
                        <v-col cols="6">{{ item.Species.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.description') }}:</v-col>
                        <v-col cols="6">{{ item.Wpritem.description ? item.Wpritem.description : item.Spec.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.grade') }}:</v-col>
                        <v-col cols="6">{{ item.Grade.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.additionalDescription') }}:</v-col>
                        <v-col cols="6">{{ item.Wpritem.add_desc }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.size') }}:</v-col>
                        <v-col cols="6">{{ item.Size.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.volume') }}:</v-col>
                        <v-col cols="6">{{ formatThisNumber(item.Wpritem.volume,uofmPrecision(item.Measurement.title)) }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.measurement') }}:</v-col>
                        <v-col cols="6">{{ item.Measurement.title }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="6" class="pl-6">{{ $t('message.unitPrice') }}:</v-col>
                        <v-col cols="6">{{ formatThisNumber(item.Wpritem.unitprice,currencyFormatPrecision) }}</v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.otherDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.totalValue') + getIncoTerm(Wpr__incoterm_id) }}:</v-col>
                <v-col cols="6">
                    <span :class="Wpr__cfrvalue != null ? null : 'red--text'">{{ Wpr__cfrvalue != null ? formatThisNumber(Wpr__cfrvalue,currencyFormatPrecision) : $t('message.valueMissing') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.containerCount') }}:</v-col>
                <v-col cols="6" v-if="Wpr__containersize_id != null">
                    <span v-if=" [1,2,3,5].includes(Wpr__containersize_id) ">{{ Wpr__containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(Wpr__containersize_id) "> X </span>
                    <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == Wpr__containersize_id).Containersize.title }}</span>
                </v-col>
                <v-col cols="6" class="red--text" v-else>{{ $t('message.valueMissing') }}</v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.suggestedETA') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__etadate != null ? null : 'red--text'">{{ Wpr__etadate != null ? formatDate(Wpr__etadate) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.currentStockDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.currentStockInSameItems') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__current_stock != null ? null : 'red--text'">{{ Wpr__current_stock != null ? Wpr__current_stock : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.currentStockPoNumber') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__ponumber != null ? null : 'red--text'">{{ Wpr__ponumber != null ? Wpr__ponumber : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaCurrentStockInSameItems') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__eta_current_stock != null ? null : 'red--text'">{{ Wpr__eta_current_stock != null ? formatDate(Wpr__eta_current_stock) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfCurrentStock') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__expected_salesdate_current != null ? null : 'red--text'">{{ Wpr__expected_salesdate_current != null ? formatDate(Wpr__expected_salesdate_current) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.intransitStockDetails') }}</v-col>
                <v-col cols="6">{{ $t('message.intransitStockInSameItems') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__transit_stock != null ? null : 'red--text'">{{ Wpr__transit_stock != null ? Wpr__transit_stock : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.intransitStockPoNumber') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__transit_ponumber != null ? null : 'red--text'">{{ Wpr__transit_ponumber != null ? Wpr__transit_ponumber: $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaIntransitStockInSameItems') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__transit_eta_stock != null ? null : 'red--text'">{{ Wpr__transit_eta_stock != null ? formatDate(Wpr__transit_eta_stock) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfInTransitStock') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__transit_expected_salesdate != null ? null : 'red--text'">{{ Wpr__transit_expected_salesdate != null ? formatDate(Wpr__transit_expected_salesdate) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters class="pt-3">
                <v-col cols="12" class="font-weight-bold">{{ $t('message.newPODetails') }}</v-col>
                <v-col cols="6">{{ $t('message.etdOfNewPo') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__etddate != null ? null : 'red--text'">{{ Wpr__etddate != null ? formatDate(Wpr__etddate) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.etaOfNewPo') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__etadate != null ? null : 'red--text'">{{ Wpr__etadate != null ? formatDate(Wpr__etadate) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.expectedTimeOfSalesOfNewPO') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__expected_salesdate_newpo != null ? null : 'red--text'">{{ Wpr__expected_salesdate_newpo != null ? formatDate(Wpr__expected_salesdate_newpo) : $t('message.valueMissing') }}</span></v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6">{{ $t('message.explanationForPurchase') }}:</v-col>
                <v-col cols="6"><span :class="Wpr__explanation != null ? null : 'red--text'">{{ Wpr__explanation != null ? Wpr__explanation : $t('message.valueMissing') }}</span></v-col>
            </v-row>
        </v-container>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, numberFormat} from "../../helpers/helpers";
    import {mapFieldsPrefixed,mapMultiRowFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'

    export default {
        name: "WarehousePurchaseRequestPreview",
        data(){
            return {}
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('wpr',{
                Wpr__cfrvalue: 'Wpr.cfrvalue',
                Wpr__containercount: 'Wpr.containercount',
                Wpr__containersize_id: 'Wpr.containersize_id',
                Wpr__currency_id: 'Wpr.currency_id',
                Wpr__current_stock: 'Wpr.current_stock',
                Wpr__eta_current_stock: 'Wpr.eta_current_stock',
                Wpr__etadate: 'Wpr.etadate',
                Wpr__etddate: 'Wpr.etddate',
                Wpr__expected_salesdate_current: 'Wpr.expected_salesdate_current',
                Wpr__expected_salesdate_newpo: 'Wpr.expected_salesdate_newpo',
                Wpr__explanation: 'Wpr.explanation',
                Wpr__incoterm_id: 'Wpr.incoterm_id',
                Wpr__office_id: 'Wpr.office_id',
                Wpr__paymentstatement_id: 'Wpr.paymentstatement_id',
                Wpr__ponumber: 'Wpr.ponumber',
                Wpr__purchaseorder_id: 'Wpr.purchaseorder_id',
                Wpr__status_id: 'Wpr.status_id',
                Wpr__supplier_id: 'Wpr.supplier_id',
                Wpr__title: 'Wpr.title',
                Wpr__transit_eta_stock: 'Wpr.transit_eta_stock',
                Wpr__transit_expected_salesdate: 'Wpr.transit_expected_salesdate',
                Wpr__transit_ponumber: 'Wpr.transit_ponumber',
                Wpr__transit_stock: 'Wpr.transit_stock',
                Wpr__wprdate: 'Wpr.wprdate',
            },'statePrefix'),
            ...mapMultiRowFieldsPrefixed('wpritem', {
                Wpritems: 'Wpritems',
            },'statePrefix'),
            ...mapGetters('certification', {
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers'
            }),
            ...mapGetters('paymentterm',{
                allSupplierPaymentTerms: 'allSupplierPaymentTerms'
            }),
            ...mapGetters([
                'certifications',
                // 'certificationsNew',
                'containerSizes',
                'currencies',
                'incoterms',
                'offices',
                'uofmQty'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_wpr')
            },
            currencyFormatPrecision () {
                return this.currencies.find((currency) => currency.Currency.id === this.Wpr__currency_id)?.Currency?.formatPrecision
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_wpr')
            }
        },
        methods: {
            ...mapActions('certification',{
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('paymentterm',{
                getAllPaymentTerms: 'getAllPaymentTerms'
            }),
            ...mapActions('supplier', {
                getAllSuppliers: 'getAllSuppliers'
            }),
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getCertification(certificationId) {
                let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
                if(certificationObj){
                    return certificationObj.Certification.title
                }
                return ''
            },
            getIncoTerm(incoTermId) {
                let incoTermObj = this.incoterms.find(i => i.Incoterm.id == incoTermId)
                if(incoTermObj){
                    return ' ' + incoTermObj.Incoterm.title
                }
                return ''
            },
            uofmPrecision (uofm) {
                return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
            },
        },
        created(){
            if(this.allSuppliers.length == 0) this.getAllSuppliers()
            if(this.allSupplierPaymentTerms.length == 0) this.getAllPaymentTerms()
            if(this.allCertifications.length == 0) this.getAllCertifications()
        }
    }
</script>

<style>
    .paper-border {
        border: dashed 1px darkgrey;
    }
    .border-top-2 {
        border-top: 2px solid black;
    }
    .border-bottom-2 {
        border-bottom: 2px solid black !important;
    }
</style>